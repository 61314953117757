.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.phoneNumberStyle {
  float: right;
  margin-top: 1px;
  font-weight: bold;

}

.phoneNumberText {
  font-style: italic;
  color: #bea34d;
  text-transform: lowercase;
}
.phoneNumber {
  text-decoration: none;
  font-size:20px;
  color: #bea34d !important;
}

.form-group {
  color: white !important;
}

.red-text {
  color: red;
}

.compensation-text {
  font-weight: bold;
}

/* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 */
 #map {
  height: 100%;
}